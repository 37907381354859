/* eslint-disable */
import Header from '../Header.vue'
import Footer from '../Footer.vue'
import LoginPop from '../Login/LoginPop.vue'
import '../../util/js/unslider.min.js'
import CheckInService from '../../api/CheckInService.js'

export default {
	data() {
		return {
			bannerList: [], //banner
			AcademicDynamicList: [], //学界动态
			newsList: [], //新闻资讯
			BookOfWeek: '', //每周一书
			JournalMonthInfo: '', //当月期刊
			CatalogGrottoList: [], //索引目录石窟
			CatalogLiteratureList: [], //索引目录文献
			CatalogRelicList: [], //索引目录文物
			CatalogBibliographyList: [], //索引目录论著
			CatalogSitesList: [], //索引目录遗址
			CatalogFigureList: [], //索引目录人物
			CatalogAdditionalList: [], //索引目录其他
			KnowlegeArtList: [], //知识服务艺术鉴赏
			KnowlegeRelicList: [], //知识服务考古文物
			KnowlegeLearnList: [], //知识服务学术探讨
			KnowlegeFigureList: [], //知识服务人物传记
			LegacyList: [], //遗珍
			AnLiteratureList: [], //古文献
			BookList: [], //研究成果图书
			BookDataList: [], //研究成果图书
			journalList: [], //研究成果期刊
			ArticalList: [], //研究成果论文
			OtherArticalList: [], //研究成果其他
			curLegacyClassId: '',
			curLegacyClassName: '',
			curLegacyClassIndex: 0,
			showLogin: false,
			exportList: [], //专家
			exportCon: '', //专家信息
			searchMechanismList: [], //研究机构库
			publishMechanismList: [], //出版机构库
			ExpertRelationEntryList: [], //专家相关论文
			VideoList: [], //最新视频
			AudioList: [], //最新音频
			defaultImage:
				'this.src="' + require('../../images/banner.png') + '"', //默认banner
			showPlayer: false,
			mediaFilePath: '', //音视频播放路径
			audioInfo: '', //音频信息
			curClassType: -1,
		}
	},
	methods: {
		GetBanner() {
			//获取banner
			var that = this
			CheckInService.GetBanner(0).then((res) => {
				if (res.Tag == 1) {
					that.bannerList = res.Result
					that.$nextTick(() => {
						$('.banner_img').unslider({
							speed: 700,
							delay: 6000,
							dots: true,
						})
					})
				}
			})
		},
		GetHotAcademicDynamicList() {
			//学界动态
			var that = this
			CheckInService.GetRecommendAcademicDynamicList(6).then((res) => {
				if (res.Tag == 1) {
					that.AcademicDynamicList = res.Result
				}
			})
		},
		GetNewsList() {
			//获取新闻资讯
			var that = this
			CheckInService.GetRecommendNewsList(4).then((res) => {
				if (res.Tag == 1) {
					that.newsList = res.Result
				}
			})
		},
		GetBookOfWeek() {
			//获取每周一书
			var that = this
			CheckInService.GetBookOfWeek().then((res) => {
				if (res.Tag == 1) {
					that.BookOfWeek = res.Result
				}
			})
		},
		GetJournalOfMonth() {
			//获取当月期刊
			var that = this
			CheckInService.GetJournalOfMonth().then((res) => {
				if (res.Tag == 1) {
					that.JournalMonthInfo = res.Result
				}
			})
		},
		GetCatalogGrottoList() {
			//获取索引目录石窟
			var that = this
			CheckInService.GetClassRecommendResList(
				that.$CatalogGrottoId,
				7,
				0
			).then((res) => {
				if (res.Tag == 1) {
					that.CatalogGrottoList = res.Result
				}
			})
		},
		GetCatalogLiteratureList() {
			//获取索引目录文献
			var that = this
			CheckInService.GetClassRecommendResList(
				that.$CatalogLiteratureId,
				6,
				0
			).then((res) => {
				if (res.Tag == 1) {
					that.CatalogLiteratureList = res.Result
				}
			})
		},
		GetCatalogRelicList() {
			//获取索引目录文物
			var that = this
			CheckInService.GetClassRecommendResList(
				that.$CatalogRelicId,
				10,
				0
			).then((res) => {
				if (res.Tag == 1) {
					that.CatalogRelicList = res.Result
				}
			})
		},
		GetCatalogBibliographyList() {
			//获取索引目录论著
			var that = this
			CheckInService.GetClassRecommendResList(
				that.$CatalogBibliographyId,
				5,
				0
			).then((res) => {
				if (res.Tag == 1) {
					that.CatalogBibliographyList = res.Result
				}
			})
		},
		GetCatalogSitesList() {
			//获取索引目录遗址
			var that = this
			CheckInService.GetClassRecommendResList(
				that.$CatalogSitesId,
				9,
				0
			).then((res) => {
				if (res.Tag == 1) {
					that.CatalogSitesList = res.Result
				}
			})
		},
		GetCatalogFigureList() {
			//获取索引目录人物
			var that = this
			CheckInService.GetClassRecommendResList(
				that.$CatalogFigureId,
				9,
				0
			).then((res) => {
				if (res.Tag == 1) {
					that.CatalogFigureList = res.Result
				}
			})
		},
		GetCatalogAdditionalList() {
			//获取索引目录其他
			var that = this
			CheckInService.GetClassRecommendResList(
				that.$CatalogAdditionalId,
				7,
				0
			).then((res) => {
				if (res.Tag == 1) {
					that.CatalogAdditionalList = res.Result
				}
			})
		},
		GetKnowlegeArtList() {
			// 获取知识服务艺术鉴赏
			var that = this
			CheckInService.GetClassRecommendResList(
				that.$KnowlegeArtId,
				7,
				4
			).then((res) => {
				if (res.Tag == 1) {
					console.log('KnowlegeArtList:', res)
					that.KnowlegeArtList = res.Result
				}
			})
		},
		GetKnowlegeRelicList() {
			// 获取知识服务考古文物
			var that = this
			CheckInService.GetClassRecommendResList(
				that.$KnowlegeRelicId,
				6,
				4
			).then((res) => {
				if (res.Tag == 1) {
					that.KnowlegeRelicList = res.Result
				}
			})
		},
		GetKnowlegeLearnList() {
			// 获取知识服务学术探讨
			var that = this
			CheckInService.GetClassRecommendResList(
				that.$KnowlegeLearnId,
				5,
				4
			).then((res) => {
				if (res.Tag == 1) {
					console.log('KnowlegeLearnList:', res)
					that.KnowlegeLearnList = res.Result
				}
			})
		},
		GetKnowlegeFigureList() {
			// 获取知识服务人物传记
			var that = this
			CheckInService.GetClassRecommendResList(
				that.$KnowlegeFigureId,
				5,
				4
			).then((res) => {
				if (res.Tag == 1) {
					that.KnowlegeFigureList = res.Result
				}
			})
		},
		GetLegacyList(legacyId) {
			//获取遗珍图片
			var that = this
			CheckInService.GetClassRecommendResList(legacyId, 11, 3).then(
				(res) => {
					if (res.Tag == 1) {
						that.LegacyList = res.Result
					}
				}
			)
		},
		GetAnLiteratureList() {
			//获取古文献列表
			var that = this
			CheckInService.GetAnLiteratureList(1, 5, '').then((res) => {
				if (res.Tag == 1) {
					that.AnLiteratureList = res.Result
				}
			})
		},
		GetBookList() {
			//获取研究学者图书
			var that = this
			CheckInService.GetClassRecommendResList(
				that.$SearchAchievtBookId,
				11,
				2
			).then((res) => {
				if (res.Tag == 1) {
					res.Result.forEach((item, index) => {
						if (index < 5) {
							that.BookList.push(item)
						} else {
							that.BookDataList.push(item)
						}
					})
				}
			})
		},
		GetJournalList() {
			//获取研究成果期刊
			var that = this
			CheckInService.GetResourceList(3, 1).then((res) => {
				if (res.Tag == 1) {
					that.journalList = res.Result
				}
			})
		},
		GerArticalList() {
			//获取研究成果论文
			var that = this
			CheckInService.GetClassRecommendResList(
				that.$SearchAchievtEntryId,
				13,
				1
			).then((res) => {
				if (res.Tag == 1) {
					that.ArticalList = res.Result
				}
			})
		},
		GerOtherArticalList() {
			//获取研究成果其他
			var that = this
			CheckInService.GetClassRecommendResList(
				that.$SearchAchievtAdditionalId,
				3,
				4
			).then((res) => {
				if (res.Tag == 1) {
					that.OtherArticalList = res.Result
				}
			})
		},
		GetExportList() {
			//获取专家学者库
			var that = this
			CheckInService.GetTopExpertList(7).then((res) => {
				if (res.Tag == 1) {
					that.exportList = res.Result
					if (that.exportList.length > 0) {
						that.exportCon = that.exportList[0]
						that.GetExpertRelationEntryList(that.exportList[0].ID)
					}
				}
			})
		},
		changeExport(index) {
			//切换专家
			var that = this
			that.exportCon = that.exportList[index]
			that.GetExpertRelationEntryList(that.exportList[index].ID)
		},
		GetExpertRelationEntryList(id) {
			//获取专家相关论文
			var that = this
			CheckInService.GetLibraryRelationList(3, id, 5).then((res) => {
				if (res.Tag == 1) {
					that.ExpertRelationEntryList = res.Result
				}
			})
		},
		GetSearchMechanismList() {
			//获取研究机构库
			var that = this
			CheckInService.GetTopMechanismList(1, 4).then((res) => {
				if (res.Tag == 1) {
					that.searchMechanismList = res.Result
				}
			})
		},
		GetPublishMechanismList() {
			//获取出版机构库
			var that = this
			CheckInService.GetTopMechanismList(0, 4).then((res) => {
				if (res.Tag == 1) {
					that.publishMechanismList = res.Result
				}
			})
		},
		GetVideoList() {
			//获取最新视频
			var that = this
			CheckInService.GetResListNoClass(1, 5, '', 5).then((res) => {
				if (res.Tag == 1) {
					that.VideoList = res.Result
				}
			})
		},
		GetAudioList() {
			//获取最新音频
			var that = this
			CheckInService.GetResListNoClass(1, 8, '', 4).then((res) => {
				if (res.Tag == 1) {
					that.AudioList = res.Result
				}
			})
		},
		toAnLiteratureList() {
			//古文献列表
			this.$router.push({
				path: '/AnLiteratureList',
			})
		},
		toReadingPdf(value) {
			//古文献pdf阅读
			var that = this
			if (that.$UserID) {
				if (that.$ServiceType == 1) {
					//vip
					if (value.PdfReadCount > 0) {
						window.open(
							this.$WebUrl +
							'AnliteratureRead?path=' +
							value.PdfReadPath +
							'&pageCount=' +
							value.PdfReadCount
						)
						CheckInService.AddAnLiteratureBrowser(
							value.ID,
							value.Name,
							that.$UserID
						).then((res) => { })
					}
				} else {
					that.$alert('请联系平台管理员开通VIP服务', '提示', {
						confirmButtonText: '确定',
					})
				}
			} else {
				that.showLogin = false
				that.showLogin = true
			}
		},
		ChangeClass(index) {
			//切换遗珍分类
			this.curLegacyClassIndex = index
			if (index == 0) {
				//彩塑
				this.GetLegacyList(this.$LegacyModelId)
				this.curLegacyClassId = this.$LegacyModelId
				this.curLegacyClassName = '彩塑'
			} else if (index == 1) {
				//壁画
				this.GetLegacyList(this.$LegacyImgId)
				this.curLegacyClassId = this.$LegacyImgId
				this.curLegacyClassName = '壁画'
			} else if (index == 2) {
				//出土文物
				this.GetLegacyList(this.$LegacyRelicId)
				this.curLegacyClassId = this.$LegacyRelicId
				this.curLegacyClassName = '出土文物'
			} else if (index == 3) {
				//石窟
				this.GetLegacyList(this.$LegacyGrottoId)
				this.curLegacyClassId = this.$LegacyGrottoId
				this.curLegacyClassName = '石窟'
			} else if (index == 4) {
				//遗址
				this.GetLegacyList(this.$LegacySitesId)
				this.curLegacyClassId = this.$LegacySitesId
				this.curLegacyClassName = '遗址'
			} else if (index == 5) {
				//文献
				this.GetLegacyList(this.$LegacyLiteratureId)
				this.curLegacyClassId = this.$LegacyLiteratureId
				this.curLegacyClassName = '文献'
			} else if (index == 6) {
				//人物
				this.GetLegacyList(this.$LegacyFigureId)
				this.curLegacyClassId = this.$LegacyFigureId
				this.curLegacyClassName = '人物'
			} else if (index == 7) {
				//艺术品
				this.GetLegacyList(this.$LegacyArtId)
				this.curLegacyClassId = this.$LegacyArtId
				this.curLegacyClassName = '艺术品'
			} else if (index == 8) {
				//出版物
				this.GetLegacyList(this.$LegacyPublishId)
				this.curLegacyClassId = this.$LegacyPublishId
				this.curLegacyClassName = '出版物'
			} else {
				//其他
				this.GetLegacyList(this.$LegacyAdditionalId)
				this.curLegacyClassId = this.$LegacyAdditionalId
				this.curLegacyClassName = '其他'
			}
		},
		toMoreList(typeName, classId, type) {
			//更多
			if (type == 0) {
				//词条
				window.open(
					this.$WebUrl +
					'ElementList?classId=' +
					classId +
					'&type=' +
					type +
					'&typeName=' +
					typeName
				)
			}
			if (type == 1) {
				//文章
				window.open(
					this.$WebUrl +
					'EntryList?classId=' +
					classId +
					'&type=' +
					type +
					'&typeName=' +
					typeName
				)
			}
			if (type == 3) {
				window.open(
					this.$WebUrl +
					'ImageList?classId=' +
					classId +
					'&type=' +
					type +
					'&typeName=' +
					typeName
				)
			}
		},
		toSearchAchievtList(type) {
			//研究成果更多
			if (type == 0) {
				window.open(this.$WebUrl + 'BookList?type=' + type)
			} else {
				window.open(
					this.$WebUrl + 'SearchAchievtEntryList?type=' + type
				)
			}
		},
		toGeneralJournaDetail(id) {
			//子期刊详情
			window.open(this.$WebUrl + 'JournalManagerDetail?id=' + id)
		},
		toJournalDetail(id) {
			//期刊详情
			window.open(this.$WebUrl + 'GeneralJournaDetail?id=' + id)
		},
		toBookDetail(id) {
			//图书详情
			window.open(this.$WebUrl + 'BookDetail?id=' + id)
		},
		toElementDetail(id) {
			//词条详情
			window.open(this.$WebUrl + 'ElementDetail?id=' + id)
		},
		toEntryDetail(id) {
			//条目详情
			window.open(this.$WebUrl + 'EntryDetail?id=' + id)
		},
		toImageDetail(id) {
			//图片详情
			window.open(this.$WebUrl + 'ImageDetail?id=' + id)
		},
		toManuscriptEdit() {
			//在线投稿
			var that = this
			if (that.$UserID) {
				that.$router.push({
					path: 'ManuscriptEditOne',
				})
			} else {
				that.showLogin = false
				that.showLogin = true
			}
		},
		toMechanismList(type) {
			//机构列表
			this.$router.push({
				path: '/MechanismList',
				query: {
					type: type,
				},
			})
		},
		playMedia(id, type) {
			//播放音视频
			var that = this
			if (that.$UserID) {
				that.curClassType = type
				CheckInService.GetMediaModel(id, that.$UserID).then((res) => {
					if (res.Tag == 1) {
						that.audioInfo = res.Result.MediaModel
						that.mediaFilePath = res.Result.MediaModel.ResPath
						that.showPlayer = true
					}
				})
			} else {
				that.showLogin = false
				that.showLogin = true
			}
		},
		hidePlayer() {
			var media = document.getElementsByClassName('media')[0]
			if (media) {
				media.pause()
			}
			this.showPlayer = false
		},
		toMediaList(type) {
			//更多音视频
			window.open(this.$WebUrl + 'MediaList?type=' + type)
		},
		toMechanismDetail(id, type) {
			//专家，机构详情
			window.open(
				this.$WebUrl + 'MechanismDetail?id=' + id + '&type=' + type
			)
		},
	},
	created() {
		this.GetBanner()
		this.GetHotAcademicDynamicList()
		this.GetNewsList()
		this.GetBookOfWeek()
		this.GetJournalOfMonth()
		this.GetCatalogGrottoList()
		this.GetCatalogLiteratureList()
		this.GetCatalogRelicList()
		this.GetCatalogBibliographyList()
		this.GetCatalogSitesList()
		this.GetCatalogFigureList()
		this.GetCatalogAdditionalList()
		this.GetKnowlegeArtList()
		this.GetKnowlegeRelicList()
		this.GetKnowlegeLearnList()
		this.GetKnowlegeFigureList()
		this.curLegacyClassId = this.$LegacyModelId
		this.curLegacyClassName = '彩塑'
		this.GetLegacyList(this.$LegacyModelId)
		this.GetAnLiteratureList()
		this.GetBookList()
		this.GetJournalList()
		this.GerArticalList()
		this.GerOtherArticalList()
		this.GetExportList()
		this.GetSearchMechanismList()
		this.GetPublishMechanismList()
		this.GetVideoList()
		this.GetAudioList()
	},
	mounted() { },
	components: {
		Header: Header,
		Footer: Footer,
		LoginPop: LoginPop,
	},
}
